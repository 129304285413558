<template>
  <div class="auth-form">
    <div class="auth-form__content">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        :hide-required-asterisk="true"
        @submit.native.prevent="submitForm('form')"
      >
        <el-form-item label="Логин" prop="login">
          <el-input v-model.trim="form.login" ref="login" type="text" />
        </el-form-item>

        <el-form-item label="Пароль" prop="password">
          <el-input v-model="form.password" type="password" show-password />
          <transition name="el-zoom-in-top">
            <div
              v-if="authErrors && typeof authErrors === 'string'"
              class="el-form-item__error"
            >
              {{ authErrors }}
            </div>
          </transition>
        </el-form-item>

        <el-form-item size="large">
          <el-checkbox v-model="rememberMe" @change="rememberMeHandler">
            Запомнить меня
          </el-checkbox>
        </el-form-item>

        <el-form-item>
          <el-button type="primary el-button--stretch" native-type="submit">
            Войти <i class="el-icon-arrow-right el-icon-right"></i>
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="auth-form__footer">
      <div class="auth-form__footer-row">
        Нет аккаунта?
        <router-link class="auth-form__link" :to="{ name: 'FreeRegistration' }">
          Регистрация
        </router-link>
      </div>
      <div class="auth-form__footer-row">
        <router-link class="auth-form__link" :to="{ name: 'ForgetPassword' }">
          Забыли пароль?
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    const checkLogin = (rule, value, callback) => {
      if (this.authErrors && this.authErrors.email) {
        callback(new Error(`${this.authErrors.email}`));
      } else {
        callback();
      }
    };
    const checkPassword = (rule, value, callback) => {
      if (this.authErrors && this.authErrors.password) {
        callback(new Error(`${this.authErrors.password}`));
      } else {
        callback();
      }
    };
    return {
      form: {
        login: "",
        password: ""
      },
      rules: {
        login: [
          {
            required: true,
            message: "Введите логин",
            trigger: ["blur", "change"]
          },
          { validator: checkLogin, trigger: ["blur"] }
        ],
        password: [
          {
            required: true,
            message: "Введите пароль",
            trigger: ["blur", "change"]
          },
          { validator: checkPassword, trigger: ["blur"] }
        ]
      },
      rememberMe: false
    };
  },
  computed: {
    authErrors() {
      return this.$store.state.Auth.errors;
    }
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.$store.commit("Auth/setData", { label: "errors", data: null });
      }
    }
  },
  mounted() {
    this.$refs.login.focus();
    this.rememberMe = localStorage.getItem("remember-me") === "true";
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.login();
        } else {
          return false;
        }
      });
    },
    login() {
      const data = {
        email: this.form.login,
        password: this.form.password
      };

      this.$store
        .dispatch("Auth/login", data)
        .then(resp => {
          const userRole = resp.data.data.user_role;
          this.$store.dispatch("Tariffs/getCurrentTariff");
          if (userRole === "employee") {
            this.$refs["form"].validate(() => {});
          } else {
            this.$router.push({ name: "Projects" });
          }
        })
        .catch(err => {
          console.error(err.response);
          this.$refs["form"].validate(() => {});
        });
    },
    rememberMeHandler(state) {
      this.$store.commit("Auth/setData", { label: "rememberMe", data: state });
    }
  }
};
</script>
