var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "auth-form"
  }, [_c('div', {
    staticClass: "auth-form__content"
  }, [_c('el-form', {
    ref: "form",
    attrs: {
      "model": _vm.form,
      "rules": _vm.rules,
      "hide-required-asterisk": true
    },
    nativeOn: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submitForm('form');
      }
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Логин",
      "prop": "login"
    }
  }, [_c('el-input', {
    ref: "login",
    attrs: {
      "type": "text"
    },
    model: {
      value: _vm.form.login,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "login", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "form.login"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Пароль",
      "prop": "password"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "password",
      "show-password": ""
    },
    model: {
      value: _vm.form.password,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "password", $$v);
      },
      expression: "form.password"
    }
  }), _c('transition', {
    attrs: {
      "name": "el-zoom-in-top"
    }
  }, [_vm.authErrors && typeof _vm.authErrors === 'string' ? _c('div', {
    staticClass: "el-form-item__error"
  }, [_vm._v(" " + _vm._s(_vm.authErrors) + " ")]) : _vm._e()])], 1), _c('el-form-item', {
    attrs: {
      "size": "large"
    }
  }, [_c('el-checkbox', {
    on: {
      "change": _vm.rememberMeHandler
    },
    model: {
      value: _vm.rememberMe,
      callback: function callback($$v) {
        _vm.rememberMe = $$v;
      },
      expression: "rememberMe"
    }
  }, [_vm._v(" Запомнить меня ")])], 1), _c('el-form-item', [_c('el-button', {
    attrs: {
      "type": "primary el-button--stretch",
      "native-type": "submit"
    }
  }, [_vm._v(" Войти "), _c('i', {
    staticClass: "el-icon-arrow-right el-icon-right"
  })])], 1)], 1)], 1), _c('div', {
    staticClass: "auth-form__footer"
  }, [_c('div', {
    staticClass: "auth-form__footer-row"
  }, [_vm._v(" Нет аккаунта? "), _c('router-link', {
    staticClass: "auth-form__link",
    attrs: {
      "to": {
        name: 'FreeRegistration'
      }
    }
  }, [_vm._v(" Регистрация ")])], 1), _c('div', {
    staticClass: "auth-form__footer-row"
  }, [_c('router-link', {
    staticClass: "auth-form__link",
    attrs: {
      "to": {
        name: 'ForgetPassword'
      }
    }
  }, [_vm._v(" Забыли пароль? ")])], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }